import { createContext, useEffect, useReducer } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import axios from "axios";
import { BASE_API_URL, WEB_GTWY_API_URL } from "src/config";
import { useDispatch } from "src/store";
import {
  updateApiMode,
  developerUpdateApiMode,
} from "src/store/actions/apiModeAction";
import {
  finmo_country_to_currency,
  finmo_platform_currency_list,
} from "src/utils/globalStaticData";
import {
  apiErrorMessage,
  setDefaultCurrency,
  toastNotification,
} from "src/utils/helper";
import {
  getKybOrgDetails,
  getOrgAccDetails,
  getAllOrgControl,
  logoutSession,
  getPartnerSetting,
  getPartnerDocument,
  getPartnerInfo,
} from "src/utils/NetworkUtils";
import { useHistory } from "react-router-dom";
import { useQuery } from "src/hooks/querys";
import { useSelector } from "react-redux";
import supportWidget from "src/utils/SupportWidget";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  otp_login_user: null,
  authData: null,
  kyb_status: "",
  is_gca_enabled: false,
  organization: {},
  org_country_currency: {},
  org_country_currency_sandbox: [],
  org_country_currency_production: [],
  org_country: [],
  org_country_sandbox: {},
  org_country_production: {},
  org_currency: [],
  org_currency_sandbox: {},
  org_currency_production: {},
  is_ifn_enabled: false,
  is_bulk_payout_enabled: false,
  is_bulk_payout_enabled_sandbox: false,
  is_sender_validation_enabled: false,
  is_sender_validation_enabled_sandbox: false,
  is_maker_checker_dashboard_enabled: false,
  is_maker_checker_dashboard_enabled_production: false,
  is_maker_checker_dashboard_enabled_sandbox: false,
  is_maker_checker_api_enabled: false,
  is_maker_checker_api_enabled_production: false,
  is_maker_checker_api_enabled_sandbox: false,
  is_gca_wft_enabled: false,
  is_gca_wft_enabled_production: false,
  is_fees_wallet_balance_visible: false,
  is_invoicing_enabled: false,
  is_invoicing_enabled_production: false,
  is_audit_trail_enabled: false,
  is_audit_trail_enabled_sandbox: false,
  is_audit_trail_enabled_production: false,
  treasury_os: false,
  treasury_os_sandbox: false,
  treasury_os_production: false,
  is_pricing_visible: false,
  is_pricing_visible_sandbox: false,
  is_pricing_visible_production: false,
  customer_wallet_payout_currency: [],
  customer_wallet_payout_currency_sandbox: [],
  customer_wallet_payout_currency_production: [],
  is_plugins_enabled: false,
  enabled_environment: [],
  is_signature_key_enabled: false,
  is_signature_key_enabled_sandbox: false,
  is_signature_key_enabled_production: false,
  is_payout_charge_bearer_enabled: false,
  is_payout_charge_bearer_enabled_sandbox: false,
  is_payout_charge_bearer_enabled_production: false,
};

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem("access_token", access_token);
    // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem("access_token");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const setSessionNew = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
    // if (key == "access_token") {
    //   axios.defaults.headers.common.Authorization = `Bearer ${value}`;
    // }
  } else {
    localStorage.removeItem(key);
    // if (key == "access_token") {
    //   delete axios.defaults.headers.common.Authorization;
    // }
  }
};
const setAuthLocalSession = (
  access_token,
  refresh_token,
  access_token_expires_at,
  refresh_token_expires_at,
  session_id
) => {
  localStorage.setItem("access_token", access_token);
  // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("access_token_expires_at", access_token_expires_at);
  localStorage.setItem("refresh_token_expires_at", refresh_token_expires_at);
  localStorage.setItem("session_id", session_id);
  dispatchEvent(new Event("storage"));
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const {
        isAuthenticated,
        user,
        org_country_currency,
        enabled_country,
        enabled_currency,
        is_bulk_payout_enabled,
        is_sender_validation_enabled,
        is_maker_checker_dashboard_enabled,
        is_maker_checker_api_enabled,
        is_ifn_enabled,
        is_gca_wft_enabled,
        is_invoicing_enabled,
        is_audit_trail_enabled,
        customer_wallet_payout_currency,
        treasury_os,
        is_pricing_visible,
        is_signature_key_enabled,
        is_fees_wallet_balance_visible,
        is_plugins_enabled,
        enabled_environment,
        is_payout_charge_bearer_enabled,
      } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        org_country_currency: org_country_currency ||
          state.org_country_currency || { AU: "AUD" },
        org_country: enabled_country || state.org_country || ["AU"],
        org_currency: enabled_currency || state.org_currency || ["AUD"],
        is_bulk_payout_enabled,
        is_sender_validation_enabled,
        is_maker_checker_dashboard_enabled,
        is_maker_checker_api_enabled,
        treasury_os,
        is_signature_key_enabled,
        is_pricing_visible: is_pricing_visible,
        is_ifn_enabled,
        is_gca_wft_enabled,
        is_invoicing_enabled,
        is_audit_trail_enabled,
        customer_wallet_payout_currency: customer_wallet_payout_currency || [],
        is_fees_wallet_balance_visible,
        is_plugins_enabled,
        is_payout_charge_bearer_enabled,
        enabled_environment,
      };
    }
    case "LOGIN": {
      const {
        user,
        authData,
        org_country_currency,
        enabled_country,
        enabled_currency,
        is_bulk_payout_enabled,
        is_sender_validation_enabled,
        is_maker_checker_dashboard_enabled,
        is_maker_checker_api_enabled,
        treasury_os,
        is_pricing_visible,
        is_signature_key_enabled,
        is_ifn_enabled,
        is_gca_wft_enabled,
        is_invoicing_enabled,
        is_audit_trail_enabled,
        customer_wallet_payout_currency,
        is_fees_wallet_balance_visible,
        is_plugins_enabled,
        is_payout_charge_bearer_enabled,
        enabled_environment,
      } = action.payload;
      const { is_bo_admin_login, is_partner_admin_login } = authData || {};
      if (is_bo_admin_login) {
        setSessionNew("is_bo_admin_login", is_bo_admin_login);
      } else {
        localStorage.removeItem("is_bo_admin_login");
      }

      if (is_partner_admin_login) {
        setSessionNew("is_partner_admin_login", is_partner_admin_login);
      } else {
        localStorage.removeItem("is_partner_admin_login");
      }

      return {
        ...state,
        isAuthenticated: true,
        otp_login_user: null,
        authData: authData || {},
        user,
        org_country_currency: org_country_currency ||
          state.org_country_currency || { AU: "AUD" },
        org_country: enabled_country || state.org_country || ["AU"],
        org_currency: enabled_currency || state.org_currency || ["AUD"],
        is_bulk_payout_enabled,
        is_sender_validation_enabled,
        is_maker_checker_dashboard_enabled,
        is_maker_checker_api_enabled,
        treasury_os,
        is_signature_key_enabled,
        is_pricing_visible: is_pricing_visible,
        is_ifn_enabled,
        is_gca_wft_enabled,
        is_invoicing_enabled,
        is_audit_trail_enabled,
        customer_wallet_payout_currency,
        is_fees_wallet_balance_visible,
        is_plugins_enabled,
        is_payout_charge_bearer_enabled,
        enabled_environment,
      };
    }
    case "OTP_LOGIN": {
      const { otp_login_user } = action.payload;

      return {
        ...state,
        otp_login_user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        otp_login_user: null,
        authData: null,
        kyb_status: "",
        org_name: "",
        is_gca_enabled: false,
        gca_prod_status: "",
        gca_sandbox_status: "",
        organization: {},
        org_country_currency: {},
        org_country: [],
        org_currency: [],
        is_bulk_payout_enabled: false,
        is_sender_validation_enabled: false,
        is_maker_checker_dashboard_enabled: false,
        is_maker_checker_api_enabled: false,
        treasury_os: false,
        is_pricing_visible: false,
        is_signature_key_enabled: false,
        is_ifn_enabled: false,
        is_gca_wft_enabled: false,
        is_invoicing_enabled: false,
        is_audit_trail_enabled: false,
        is_fees_wallet_balance_visible: false,
        customer_wallet_payout_currency: [],
        is_plugins_enabled: false,
        is_payout_charge_bearer_enabled: false,
        enabled_environment: [],
      };
    }
    case "SET_KYB_DATA": {
      const {
        org_id,
        status,
        org_name,
        country,
        is_gca_enabled,
        is_cross_border_settlement_allowed,
        dispute_email,
        primary_fees_currency,
        gca_activation_status,
        is_analytics_enabled,
        partner_id,
        partner_logo,
        partner_permission_list,
        partner_permissions_agreement_data,
      } = action.payload;

      let gca_sandbox_status = "";
      let gca_prod_status = "";

      if (gca_activation_status === "COMPLETED" || !!is_gca_enabled) {
        gca_sandbox_status = "COMPLETED";
      } else {
        gca_sandbox_status = "PENDING";
      }

      if (gca_activation_status === "COMPLETED") {
        if (!!is_gca_enabled) {
          gca_prod_status = "COMPLETED";
        } else {
          gca_prod_status = "ACTIVATED";
        }
      } else {
        gca_prod_status = "PENDING";
      }

      return {
        ...state,
        kyb_status: status,
        org_name: org_name,
        is_gca_enabled,
        gca_prod_status,
        gca_sandbox_status,
        organization: {
          org_id,
          country: country,
          is_cross_border_settlement_allowed,
          dispute_email,
          primary_fees_currency,
          gca_activation_status,
          is_analytics_enabled,
          partner_id,
          partner_logo,
          partner_permission_list,
          partner_permissions_agreement_data,
        },
      };
    }

    case "SET_ORG_CONTROLS": {
      const res = action.payload;
      return {
        ...state,
        ...res,
      };
    }

    case "SWITCH_ORG_CONTROLS": {
      const { mode } = action.payload;

      if (mode === "live") {
        return {
          ...state,
          is_bulk_payout_enabled: state.is_bulk_payout_enabled_production,
          is_sender_validation_enabled:
            state.is_sender_validation_enabled_production,
          is_maker_checker_dashboard_enabled:
            state.is_maker_checker_dashboard_enabled_production,
          is_maker_checker_api_enabled:
            state.is_maker_checker_api_enabled_production,
          is_payout_charge_bearer_enabled:
            state.is_payout_charge_bearer_enabled_production,
          treasury_os: state.treasury_os_production,
          is_pricing_visible: state.is_pricing_visible_production,
          is_signature_key_enabled: state.is_signature_key_enabled_production,
          is_ifn_enabled: state.is_ifn_enabled_production,
          org_country_currency: state.org_country_currency_production,
          org_country: state.org_country_production,
          org_currency: state.org_currency_production,
          is_gca_wft_enabled: state.is_gca_wft_enabled_production,
          is_invoicing_enabled: state.is_invoicing_enabled_production,
          is_audit_trail_enabled: state.is_audit_trail_enabled_production,
          is_fees_wallet_balance_visible: state.is_fees_wallet_balance_visible,
          is_plugins_enabled: state.is_plugins_enabled,
          enabled_environment: state.enabled_environment,
          customer_wallet_payout_currency:
            state.customer_wallet_payout_currency_production || [],
        };
      } else if (mode === "test") {
        return {
          ...state,
          is_bulk_payout_enabled: state.is_bulk_payout_enabled_sandbox,
          is_sender_validation_enabled:
            state.is_sender_validation_enabled_sandbox,
          is_maker_checker_dashboard_enabled:
            state.is_maker_checker_dashboard_enabled_sandbox,
          is_maker_checker_api_enabled:
            state.is_maker_checker_api_enabled_sandbox,
          is_payout_charge_bearer_enabled:
            state.is_payout_charge_bearer_enabled_sandbox,
          treasury_os: state.treasury_os_sandbox,
          is_pricing_visible: state.is_pricing_visible_sandbox,
          is_signature_key_enabled: state.is_signature_key_enabled_sandbox,
          is_ifn_enabled: state.is_ifn_enabled_sandbox,
          org_country_currency: state.org_country_currency_sandbox,
          org_country: state.org_country_sandbox,
          org_currency: state.org_currency_sandbox,
          is_gca_wft_enabled: false,
          is_invoicing_enabled: false,
          is_fees_wallet_balance_visible: state.is_fees_wallet_balance_visible,
          is_audit_trail_enabled: state.is_audit_trail_enabled_sandbox,
          is_plugins_enabled: state.is_plugins_enabled,
          enabled_environment: state.enabled_environment,
          customer_wallet_payout_currency:
            state.customer_wallet_payout_currency_sandbox || [],
        };
      }
    }

    case "RESET": {
      return {
        isAuthenticated: false,
        isInitialised: false,
        user: null,
        otp_login_user: null,
        authData: null,
        kyb_status: "",
        is_gca_enabled: false,
        gca_sandbox_status: "",
        gca_prod_status: "",
        organization: {},
        org_country_currency: {},
        org_country: [],
        org_currency: [],
        is_bulk_payout_enabled: false,
        is_sender_validation_enabled: false,
        is_maker_checker_dashboard_enabled: false,
        is_maker_checker_api_enabled: false,
        treasury_os: false,
        is_pricing_visible: false,
        is_signature_key_enabled: false,
        is_ifn_enabled: false,
        is_gca_wft_enabled: false,
        is_invoicing_enabled: false,
        is_audit_trail_enabled: false,
        is_fees_wallet_balance_visible: false,
        customer_wallet_payout_currency: [],
        is_plugins_enabled: false,
        is_payout_charge_bearer_enabled: false,
        enabled_environment: [],
      };
    }
    case "UPDATE_USER": {
      const { user } = action.payload;
      return { ...state, user };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  otp_login: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  bo_login: () => Promise.resolve(),
  partner_login: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { mode } = useSelector((state) => state.mode);

  const getKybData = async () => {
    try {
      const { success, data } = await getKybOrgDetails([
        {
          filter_key: "organization_fields",
          filter_value:
            "org_id,name,country,is_gca_enabled,is_cross_border_settlement_allowed,dispute_email,primary_fees_currency,gca_activation_status,is_analytics_enabled,partner_id,partner_permission_list,partner_permissions_agreement_data",
        },
        {
          filter_key: "kyb_fields",
          filter_value: "status",
        },
      ]);
      if (success) {
        const { kyb, organization } = data || {};
        const { status } = kyb || {};
        const {
          org_id,
          name,
          country,
          is_gca_enabled,
          is_cross_border_settlement_allowed,
          dispute_email,
          primary_fees_currency,
          gca_activation_status,
          is_analytics_enabled,
          partner_id,
          partner_permission_list,
          partner_permissions_agreement_data,
        } = organization || {};

        let partner_logo = "";

        if (!!partner_id) {
          const partner_setting = await getPartnerSetting(
            "partner_logo",
            partner_id
          );
          const document_id = partner_setting?.data?.value?.[0];
          if (!!document_id) {
            const partner_document = await getPartnerDocument(
              partner_id,
              document_id
            );
            partner_logo = partner_document.data?.download_url;
          }
        }

        dispatch({
          type: "SET_KYB_DATA",
          payload: {
            org_id,
            status: status,
            org_name: name,
            country,
            is_gca_enabled,
            is_cross_border_settlement_allowed,
            dispute_email,
            primary_fees_currency,
            gca_activation_status,
            is_analytics_enabled,
            partner_id,
            partner_logo,
            partner_permission_list,
            partner_permissions_agreement_data,
          },
        });
      }
      return data;
    } catch (err) {
      toastNotification("error", apiErrorMessage(err), 2000);
    }
  };

  const getPartnerData = async () => {
    try {
      const { data } = await getPartnerInfo();
      return data;
    } catch (err) {}
  };

  const fetchAllOrgControls = async () => {
    let org_controls = {};
    try {
      const { data } = await getAllOrgControl([
        { filter_key: "limit", filter_value: 10000 },
        { filter_key: "environment", filter_value: "production" },
        { filter_key: "environment", filter_value: "sandbox" },
      ]);
      data.forEach((obj) => {
        if (!Object.keys(org_controls).includes(obj.key_type)) {
          org_controls = {
            ...org_controls,
            [obj.key_type]: data.filter(
              (item) => item.key_type === obj.key_type
            ),
          };
        }
      });
    } catch (err) {}
    return org_controls;
  };

  const getCustomerWalletPayoutEnabledCurrency = async (mode, org_controls) => {
    let customer_wallet_payout_currency = [];

    let currency_data = org_controls?.["CUSTOMER_WALLET_PAYOUT"]?.filter(
      (item) => item?.environment === mode
    );

    if (!!currency_data) {
      currency_data.forEach((currency) => {
        if (
          currency?.value !== null &&
          currency?.value?.is_enabled &&
          finmo_platform_currency_list.includes(currency?.key)
        ) {
          customer_wallet_payout_currency.push(currency?.key);
        }
      });
    }

    return { customer_wallet_payout_currency };
  };

  const getEnabledCountryCurrency = async (mode, org_controls) => {
    let org_country_currency = {};
    let enabled_country = [];
    let enabled_currency = [];

    let country_data = org_controls?.["COUNTRY"]?.filter(
      (item) => item.environment === mode
    );
    let currency_data = org_controls?.["CURRENCY"]?.filter(
      (item) => item.environment === mode
    );

    country_data.forEach((country) => {
      if (
        country?.value !== null &&
        country?.value?.is_enabled &&
        Object.keys(finmo_country_to_currency).includes(country?.key)
      ) {
        let enabled_currency_for_country = [];
        currency_data.forEach((currency) => {
          if (
            currency?.value !== null &&
            currency?.value?.is_enabled &&
            finmo_country_to_currency[country?.key].includes(currency?.key)
          ) {
            enabled_country.push(country?.key);
            enabled_currency.push(currency?.key);
            enabled_currency_for_country.push(currency?.key);
          }
        });
        if (enabled_currency_for_country.length > 0) {
          org_country_currency[country?.key] = enabled_currency_for_country;
        }
      }
    });

    return { org_country_currency, enabled_country, enabled_currency };
  };

  const getEnvSpecificControls = async (org_controls, mode_env) => {
    const controls_list = [
      {
        key_type: "BULK_PAYOUT",
        key_name: "is_bulk_payout_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "SENDER_VALIDATION",
        key_name: "is_sender_validation_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "MAKER_CHECKER_DASHBOARD",
        key_name: "is_maker_checker_dashboard_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "MAKER_CHECKER_API",
        key_name: "is_maker_checker_api_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "PAYOUT_CHARGE_BEARER",
        key_name: "is_payout_charge_bearer_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "INBOUND_FUND_NOTICE",
        key_name: "is_ifn_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "FEES_WALLET_BALANCE",
        key_name: "is_fees_wallet_balance_visible",
        env: ["production", "sandbox"],
      },
      {
        key_type: "INVOICE",
        key_name: "is_invoicing_enabled",
        env: ["production"],
      },
      {
        key_type: "GCA_WFT",
        key_name: "is_gca_wft_enabled",
        env: ["production"],
      },
      {
        key_type: "AUDIT_TRAIL",
        key_name: "is_audit_trail_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "TREASURY_OS",
        key_name: "treasury_os",
        env: ["production", "sandbox"],
      },
      {
        key_type: "SIGNATURE_KEY",
        key_name: "is_signature_key_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "PRICING",
        key_name: "is_pricing_visible",
        env: ["production", "sandbox"],
      },
      {
        key_type: "PLUGIN",
        key_name: "is_plugins_enabled",
        env: ["production", "sandbox"],
      },
      {
        key_type: "ORG_ENVIRONMENT",
        key_name: "enabled_environment",
        env: ["production"],
      },
    ];

    let keys = {
      is_bulk_payout_enabled: false,
      is_sender_validation_enabled: false,
      is_maker_checker_dashboard_enabled: false,
      is_maker_checker_api_enabled: false,
      is_gca_wft_enabled: false,
      is_invoicing_enabled: false,
      is_audit_trail_enabled: false,
      treasury_os: false,
      is_pricing_visible: false,
      is_signature_key_enabled: false,
      is_fees_wallet_balance_visible: false,
      is_plugins_enabled: false,
      is_payout_charge_bearer_enabled: false,
      enabled_environment: [],
    };
    controls_list.forEach((obj) => {
      if (org_controls[obj.key_type] && obj.env.includes(mode_env)) {
        keys[obj.key_name] =
          org_controls[obj.key_type].filter(
            (item) => item.environment === mode_env && item.key === obj.key_name
          )[0]?.value || false;
      } else if (obj.key_name === "is_pricing_visible") {
        keys[obj.key_name] = true;
      }
    });

    return keys;
  };

  const getOrgControls = async (mode_env = "", is_developer = false) => {
    const org_controls = await fetchAllOrgControls();

    let enabled_env = [];
    if (Object.keys(org_controls).includes("ORG_ENVIRONMENT")) {
      enabled_env = org_controls["ORG_ENVIRONMENT"][0].value || [];
      if (
        !is_developer &&
        (enabled_env.includes("SANDBOX") || enabled_env.includes("PRODUCTION"))
      ) {
        reduxDispatch(
          updateApiMode(enabled_env[0] === "SANDBOX" ? "test" : "live")
        );
      }
    }

    const sandbox_keys = await getEnvSpecificControls(org_controls, "sandbox");
    const prod_keys = await getEnvSpecificControls(org_controls, "production");

    const cc_sandbox = await getEnabledCountryCurrency("sandbox", org_controls);
    const cc_prod = await getEnabledCountryCurrency("production", org_controls);

    const customer_wallet_payout_currency_sandbox =
      await getCustomerWalletPayoutEnabledCurrency("sandbox", org_controls);
    const customer_wallet_payout_currency_production =
      await getCustomerWalletPayoutEnabledCurrency("production", org_controls);

    dispatch({
      type: "SET_ORG_CONTROLS",
      payload: {
        org_country_currency_production: cc_prod.org_country_currency,
        org_country_production: cc_prod.enabled_country,
        org_currency_production: cc_prod.enabled_currency,
        org_country_currency_sandbox: cc_sandbox.org_country_currency,
        org_country_sandbox: cc_sandbox.enabled_country,
        org_currency_sandbox: cc_sandbox.enabled_currency,
        is_bulk_payout_enabled_production: prod_keys.is_bulk_payout_enabled,
        is_bulk_payout_enabled_sandbox: sandbox_keys.is_bulk_payout_enabled,
        is_sender_validation_enabled_production:
          prod_keys.is_sender_validation_enabled,
        is_sender_validation_enabled_sandbox:
          sandbox_keys.is_sender_validation_enabled,
        is_maker_checker_dashboard_enabled_production:
          prod_keys.is_maker_checker_dashboard_enabled,
        is_maker_checker_dashboard_enabled_sandbox:
          sandbox_keys.is_maker_checker_dashboard_enabled,
        is_maker_checker_api_enabled_production:
          prod_keys.is_maker_checker_api_enabled,
        is_maker_checker_api_enabled_sandbox:
          sandbox_keys.is_maker_checker_api_enabled,
        treasury_os_sandbox: sandbox_keys.treasury_os,
        treasury_os_production: prod_keys.treasury_os,
        is_pricing_visible_sandbox: sandbox_keys.is_pricing_visible,
        is_pricing_visible_production: prod_keys.is_pricing_visible,
        is_signature_key_enabled_sandbox: sandbox_keys.is_signature_key_enabled,
        is_signature_key_enabled_production: prod_keys.is_signature_key_enabled,
        is_ifn_enabled_production: prod_keys.is_ifn_enabled,
        is_ifn_enabled_sandbox: sandbox_keys.is_ifn_enabled,
        is_gca_wft_enabled_production: prod_keys.is_gca_wft_enabled,
        is_invoicing_enabled_production: prod_keys.is_invoicing_enabled,
        is_fees_wallet_balance_visible:
          prod_keys.is_fees_wallet_balance_visible,
        is_audit_trail_enabled_production: prod_keys.is_audit_trail_enabled,
        is_audit_trail_enabled_sandbox: sandbox_keys.is_audit_trail_enabled,
        customer_wallet_payout_currency_sandbox:
          customer_wallet_payout_currency_sandbox.customer_wallet_payout_currency ||
          [],
        customer_wallet_payout_currency_production:
          customer_wallet_payout_currency_production.customer_wallet_payout_currency ||
          [],
        is_plugins_enabled: prod_keys.is_plugins_enabled,
        is_payout_charge_bearer_enabled_sandbox:
          sandbox_keys.is_payout_charge_bearer_enabled,
        is_payout_charge_bearer_enabled_production:
          prod_keys.is_payout_charge_bearer_enabled,
        enabled_environment: enabled_env,
      },
    });

    if (mode_env === "sandbox") {
      return {
        ...cc_sandbox,
        ...sandbox_keys,
        ...customer_wallet_payout_currency_sandbox,
        is_fees_wallet_balance_visible:
          prod_keys.is_fees_wallet_balance_visible,
        enabled_environment: enabled_env,
      };
    } else {
      return {
        ...cc_prod,
        ...prod_keys,
        ...customer_wallet_payout_currency_production,
        enabled_environment: enabled_env,
      };
    }
  };

  const otp_login = async (email, password, recaptcha_token) => {
    try {
      const response = await axios.post(`${WEB_GTWY_API_URL}/login`, {
        email,
        password,
        "g-recaptcha-response": recaptcha_token,
      });
      const { success, data } = response.data;
      if (success) {
        if (!data.is_otp_required) {
          setAuthLocalSession(
            data.access_token,
            data.refresh_token,
            data.access_token_expires_at,
            data.refresh_token_expires_at,
            data.session_id
          );
          const orgData = await getOrgAccDetails();
          let request_mode = "test";
          if (data.is_developer) {
            reduxDispatch(developerUpdateApiMode("test"));
          } else {
            const mode = localStorage.getItem("apiMode");
            reduxDispatch(updateApiMode(mode ? mode : "live"));
            request_mode = mode ? mode : "live";
          }
          if (orgData.success) {
            const { organization } = await getKybData();
            let partner_info = {};
            if (!!organization?.partner_id) {
              partner_info = await getPartnerData();
            }

            const org_controls = await getOrgControls(
              request_mode === "live" ? "production" : "sandbox",
              data.is_developer
            );

            dispatch({
              type: "LOGIN",
              payload: {
                user: {
                  ...orgData.data,
                  partner_id: partner_info?.partner_id,
                  partner_level: partner_info?.partner_level,
                  is_reliance_agreement_approved:
                    partner_info?.reliance_agreement_approval_status ===
                      "APPROVED" || false,
                },
                authData: { ...data },
                ...org_controls,
              },
            });

            initializeSupportWidget(orgData?.data);
          }

          toastNotification("success", "Login successful", 1500);
          if (!data.is_email_verified) {
            history.push("/email/verification");
          }
          return;
        }
        setSessionNew("password_reset_required", data.password_reset_required);
        dispatch({
          type: "OTP_LOGIN",
          payload: {
            otp_login_user: { ...data },
          },
        });
        if (data.is_otp_sent_on_mobile || data.is_otp_sent_on_email) {
          toastNotification("success", " Login OTP sent successfully", 1500);
        }
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const login = async (sessionId, authId, otp) => {
    try {
      const response = await axios.post(`${WEB_GTWY_API_URL}/verifyLoginOtp`, {
        auth_id: authId,
        session_id: sessionId,
        otp: otp,
      });
      const { success, data } = response.data;
      if (success) {
        setAuthLocalSession(
          data.access_token,
          data.refresh_token,
          data.access_token_expires_at,
          data.refresh_token_expires_at,
          data.session_id
        );
        let request_mode = "test";
        const orgData = await getOrgAccDetails();
        if (data.is_developer) {
          reduxDispatch(developerUpdateApiMode("test"));
        } else {
          const mode = localStorage.getItem("apiMode");
          reduxDispatch(updateApiMode(mode ? mode : "live"));
          request_mode = mode ? mode : "live";
        }
        let partner_info = {};
        if (orgData.success) {
          const { organization } = await getKybData();
          if (!!organization?.partner_id) {
            partner_info = await getPartnerData();
          }
        }

        const org_controls = await getOrgControls(
          request_mode === "live" ? "production" : "sandbox",
          data.is_developer
        );

        dispatch({
          type: "LOGIN",
          payload: {
            user: {
              ...orgData.data,
              partner_id: partner_info?.partner_id,
              partner_level: partner_info?.partner_level,
              is_reliance_agreement_approved:
                partner_info?.reliance_agreement_approval_status ===
                  "APPROVED" || false,
            },
            authData: { ...data },
            ...org_controls,
          },
        });
        initializeSupportWidget(orgData?.data);
        toastNotification("success", "Login successful", 1500);
      }
    } catch (error) {
      //Login - Too many requests || Gone
      toastNotification("error", apiErrorMessage(error), 2000);
      if (error?.response?.status === 410 || error?.response?.status === 429) {
        dispatch({
          type: "RESET",
        });
        (async () => {
          await initialise();
        })();
      }
    }
  };

  const bo_login = async (bo_token) => {
    try {
      const response = await axios.get(
        `${WEB_GTWY_API_URL}/organization-user/bo-admin-login?bo_admin_login_token=${bo_token}`
      );
      const { success, data } = response.data;
      if (success) {
        setAuthLocalSession(
          data.access_token,
          data.refresh_token,
          data.access_token_expires_at,
          data.refresh_token_expires_at,
          data.session_id
        );
        const orgData = await getOrgAccDetails();
        const { is_developer } = orgData?.data || {};
        let request_mode = "test";
        if (is_developer) {
          reduxDispatch(developerUpdateApiMode("test"));
        } else {
          reduxDispatch(updateApiMode("live"));
          request_mode = "live";
        }
        if (orgData.success) {
          const { organization } = await getKybData();

          const org_controls = await getOrgControls(
            request_mode === "live" ? "production" : "sandbox",
            is_developer
          );
          let partner_info = {};
          if (!!organization?.partner_id) {
            partner_info = await getPartnerData();
          }
          dispatch({
            type: "LOGIN",
            payload: {
              user: {
                ...orgData.data,
                partner_id: partner_info?.partner_id,
                partner_level: partner_info?.partner_level,
                is_reliance_agreement_approved:
                  partner_info?.reliance_agreement_approval_status ===
                    "APPROVED" || false,
              },
              authData: { ...data },
              ...org_controls,
            },
          });
        }
        initializeSupportWidget(orgData?.data);
        toastNotification("success", "BO user login successful", 1500);
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const partner_login = async (partner_token) => {
    try {
      const response = await axios.get(
        `${WEB_GTWY_API_URL}/organization-user/partner-admin-login?partner_admin_login_token=${partner_token}`
      );
      const { success, data } = response.data;
      if (success) {
        setAuthLocalSession(
          data.access_token,
          data.refresh_token,
          data.access_token_expires_at,
          data.refresh_token_expires_at,
          data.session_id
        );
        const orgData = await getOrgAccDetails();
        const { is_developer } = orgData?.data || {};
        let request_mode = "test";
        if (is_developer) {
          reduxDispatch(developerUpdateApiMode("test"));
        } else {
          reduxDispatch(updateApiMode("live"));
          request_mode = "live";
        }
        if (orgData.success) {
          const { organization } = await getKybData();
          const permission_list = organization.partner_permission_list || [];

          let isAdmin = false;
          if (permission_list.includes("is_initiating_txn_allowed")) {
            isAdmin = true;
          } else {
            isAdmin = false;
          }

          const org_controls = await getOrgControls(
            request_mode === "live" ? "production" : "sandbox"
          );
          let partner_info = {};
          if (!!organization?.partner_id) {
            partner_info = await getPartnerData();
          }

          dispatch({
            type: "LOGIN",
            payload: {
              user: {
                ...orgData.data,
                is_admin: isAdmin,
                is_developer: false,
                is_partner_admin_login: true,
                partner_id: partner_info?.partner_id,
                partner_level: partner_info?.partner_level,
                is_reliance_agreement_approved:
                  partner_info?.reliance_agreement_approval_status ===
                    "APPROVED" || false,
              },
              authData: { ...data },
              ...org_controls,
            },
          });
        }
        toastNotification("success", "Partner user login successful", 1500);
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const logout = async () => {
    const session_id = localStorage.getItem("session_id");
    try {
      const { success } = await logoutSession(session_id);
      if (success) {
        setSession(null);
        localStorage.clear();
        setDefaultCurrency();
        dispatchEvent(new Event("storage"));
        delete axios.defaults.headers.common.Authorization;
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      setSession(null);
      localStorage.clear();
      setDefaultCurrency();
      dispatchEvent(new Event("storage"));
      delete axios.defaults.headers.common.Authorization;
      dispatch({ type: "LOGOUT" });
    }
    supportWidget.hide();
    toastNotification("success", "Logout successful", 1500);
  };

  const register = async (
    first_name,
    last_name,
    email,
    org_name,
    org_country,
    password,
    recaptcha_token,
    referral_code
  ) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/register/3f5723c615`, {
        first_name,
        last_name,
        email,
        org_name,
        org_country,
        password,
        "g-recaptcha-response": recaptcha_token,
        referral_code,
      });

      const { success, data } = response.data;
      if (success && data?.is_active) {
        toastNotification("success", "User registration successful", 1500);
        setAuthLocalSession(
          data.access_token,
          data.refresh_token,
          data.access_token_expires_at,
          data.refresh_token_expires_at
        );
        const orgData = await getOrgAccDetails();
        reduxDispatch(updateApiMode("live"));
        if (orgData.success) {
          await getKybData();
          const {
            enabled_country_currency_map,
            enabled_country,
            enabled_currency,
          } = await getOrgControls("production");
          dispatch({
            type: "LOGIN",
            payload: {
              user: { ...orgData.data },
              authData: { ...data },
              org_country_currency: enabled_country_currency_map,
              enabled_country,
              enabled_currency,
            },
          });
        }
        initializeSupportWidget(orgData?.data);
        toastNotification("success", "Login successful", 1500);
      }
      if (response.data.success) {
        return response.data;
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const initialise = async (onlyUser = false, apiModeSwitch = false) => {
    try {
      const access_token = window.localStorage.getItem("access_token");
      const is_partner_admin_login =
        window.localStorage.getItem("is_partner_admin_login") || false;
      const is_bo_admin_login =
        window.localStorage.getItem("is_bo_admin_login") || false;

      if (access_token) {
        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          let organization = {};

          if ((!onlyUser && !apiModeSwitch) || is_bo_admin_login) {
            const data = await getKybData();
            organization = data.organization;
          }
          let partner_info = {};
          if (!!organization?.partner_id) {
            partner_info = await getPartnerData();
          }
          if (!onlyUser) {
            if (!apiModeSwitch) {
              const org_controls = await getOrgControls(
                (apiModeSwitch || mode) === "live" ? "production" : "sandbox",
                orgData.data["is_developer"]
              );

              const permission_list =
                organization.partner_permission_list || [];

              if (is_partner_admin_login) {
                let isAdmin = false;

                if (permission_list.includes("is_initiating_txn_allowed")) {
                  isAdmin = true;
                } else {
                  isAdmin = false;
                }

                dispatch({
                  type: "INITIALISE",
                  payload: {
                    isAuthenticated: true,
                    user: {
                      ...orgData.data,
                      is_admin: isAdmin,
                      is_developer: false,
                      is_partner_admin_login: true,
                      partner_id: partner_info?.partner_id,
                      partner_level: partner_info?.partner_level,
                      is_reliance_agreement_approved:
                        partner_info?.reliance_agreement_approval_status ===
                          "APPROVED" || false,
                    },
                    ...org_controls,
                  },
                });
              } else {
                dispatch({
                  type: "INITIALISE",
                  payload: {
                    isAuthenticated: true,
                    user: {
                      ...orgData.data,
                      partner_id: partner_info?.partner_id,
                      partner_level: partner_info?.partner_level,
                      is_reliance_agreement_approved:
                        partner_info?.reliance_agreement_approval_status ===
                          "APPROVED" || false,
                    },
                    ...org_controls,
                  },
                });
              }

              initializeSupportWidget(orgData?.data);
            } else {
              dispatch({
                type: "SWITCH_ORG_CONTROLS",
                payload: {
                  mode: apiModeSwitch,
                },
              });
            }
          } else {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                user: {
                  ...orgData.data,
                  partner_id: partner_info?.partner_id,
                  partner_level: partner_info?.partner_level,
                  is_reliance_agreement_approved:
                    partner_info?.reliance_agreement_approval_status ===
                      "APPROVED" || false,
                },
              },
            });
            initializeSupportWidget(orgData?.data);
          }
        }
      } else {
        localStorage.clear();
        setDefaultCurrency();
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      localStorage.clear();
      setDefaultCurrency();
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };
  const queryParam = useQuery();
  const bo_token = queryParam.get("bo_admin_login_token");
  const partner_token = queryParam.get("partner_admin_login_token");

  useEffect(() => {
    if (bo_token || partner_token) {
      localStorage.clear();
      setDefaultCurrency();
      dispatch(new Event("storage"));
    }
    (async () => {
      await initialise();
    })();
  }, []);

  const initializeSupportWidget = (user_data) => {
    const { email, first_name, last_name, org_id } = user_data;
    const name = `${first_name} ${last_name}`;

    //Added temporarily for Paynow
    if (org_id === "org_e9d930b46a8e4cfbab5a03bf249b65ec") {
      supportWidget.show();
      supportWidget.prefillCustomer({ name: name, email: email });
      supportWidget.prefillForm({ custom_fields: { cf_organization: org_id } });
      supportWidget.hideFormFields([
        "name",
        "email",
        "custom_fields.cf_organization",
      ]);
    }
  };

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        otp_login,
        login,
        logout,
        initialise,
        register,
        dispatch,
        bo_login,
        partner_login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
